<template>
  <div class="404-content">
    404
  </div>
</template>

<script>
export default {
  name: "404",
  components: {},

  setup() {
    return {};
  }
};
</script>

<style lang="less"></style>
